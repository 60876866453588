<template>
  <Button
    class="button-clear ml-s1"
    @click="toggleTooltip"
    @mouseenter="showTooltip"
    @mouseleave="hideTooltip"
    aria:haspopup="true"
    aria-controls="overlay_panel"
    :id="iconId"
  >
    <i class="pi pi-question-circle tooltip-icon" :class="getColor()"></i>
  </Button>
  <OverlayPanel
    ref="tooltipId"
    appendTo="body"
    :showCloseIcon="false"
    id="tooltip_panel"
    class="tooltip px-s3"
  >
    <div class="tooltip-text">
      <template v-if="tooltip">{{ tooltip }}</template>
      <slot></slot>
    </div>
  </OverlayPanel>
</template>
<script setup>
import { computed, onMounted, onUnmounted, ref } from 'vue';
import { UniqueComponentId } from 'primevue/utils';

const props = defineProps({
  tooltip: String,
  color: { type: String, default: '' },
});

const tooltipId = ref();
const isTouch = computed(
  () => 'ontouchstart' in window || navigator.maxTouchPoints,
);
const iconId = UniqueComponentId();

const toggleTooltip = event => {
  if (isTouch.value) tooltipId.value.toggle(event);
};
const showTooltip = event => {
  if (!isTouch.value) tooltipId.value.show(event);
};
const hideTooltip = () => {
  tooltipId.value.hide();
};
const getColor = () => {
  return 'text-' + props.color;
};

onMounted(() => {
  document.addEventListener('scroll', hideTooltip);
});
onUnmounted(() => {
  document.removeEventListener('scroll', hideTooltip);
});
</script>

<style scoped lang="scss">
.pi {
  font-size: inherit;
  opacity: 0.6;
  position: relative;
  cursor: pointer;
  color: var(--tooltip-icon-color);
}
</style>
