<template>
  <div class="panel-banner">
    <div
      class="flex flex-column md:flex-row justify-content-between align-items-center"
    >
      <div class="flex">
        <Icon name="portfolio" class="text-primary" />
        <div class="h3 text-gradient-light ml-s2">
          Earn more with Yield pools!
        </div>
      </div>
      <Button
        class="button-primary ml-auto w-full md:w-auto mt-s3 md:mt-s0"
        @click="openLink('https://app.blueshift.fi/#/staking')"
        label="Stake BLUES"
      />
    </div>
  </div>
</template>

<script setup>
import Icon from '@/components/Icon';
import { useLayout } from '@/composables/core/useLayout';

const { openLink } = useLayout();
</script>
<style scoped lang="scss">
.panel-banner {
  @extend %panel;
  padding: 20px 28px;
  border-color: var(--primary-50);
  .mobile & {
    padding: 16px;
  }
}
</style>
