<template>
  <div :class="layout" class="panel-section-header">
    <div class="h1 text-primary">
      Claim your <br v-if="isMobile" />BLUES tokens
    </div>
    <div class="mt-s3 w-12 md:w-9 lg:w-10" :class="{ 'text-sm': isMobile }">
      If you are a Blueshift investor, you can use this app to claim BLUES
      tokens during the vesting period. <br v-if="isDesktop" />
      Connect your Milkomeda wallet specified in the investment agreement to
      claim.
    </div>
    <VestingInfo v-if="isInjected && !isNetworkError && !isNotListed" />
  </div>
</template>

<script setup>
import { useLayout } from '@/composables/core/useLayout';
import VestingInfo from '@/views/vesting/VestingInfo';
import { useWallet } from '@/composables/core/useWallet';
import { useVesting } from '@/composables/core/useVesting';

const { layout, isMobile, isDesktop } = useLayout();
const { isInjected, isNetworkError } = useWallet();
const { isNotListed } = useVesting();
</script>

<style lang="scss" scoped>
.panel-section-header {
  .layout-desktop & {
    align-items: center;
    flex-wrap: nowrap;
  }
  .layout-tablet & {
    flex-wrap: wrap;
  }
  .layout-mobile & {
    flex-wrap: wrap;
  }
}
</style>
