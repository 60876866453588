import { useBluesBalance } from '@/composables/core/useBluesBalance';
import { VESING_ABI } from '@/constants/abi/VESTING_ABI';
import { bluesDecimals, vestingContractAddress } from '@/envinroment';
import {
  secondsToMonths,
  toWei,
  unixTimeToDate,
} from '@/helpers/blockchain-utils';
import { format } from '@/helpers/format';
import { formatDate } from '@/helpers/format-date';
import { TokenAmount } from '@/helpers/token-amount';
import { VestingScheduleDto } from '@/models/dto/VestingSchedule.dto';
import BigNumber from 'bignumber.js';
import { computed, ComputedRef, ref, watch } from 'vue';
import { useWallet } from '@/composables/core/useWallet';
import { IVesting } from '@/interfaces/vesting.interface';
import { TOTAL_BLUES_AMOUNT } from '@/constants/TOTAL_BLUES_AMOUNT';
import type { TransactionReceipt } from 'web3-eth';

const { address, web3, isNetworkError } = useWallet();
const { updateBalance } = useBluesBalance();

const vestingContract = computed(() => {
  if (web3.value && !isNetworkError.value) {
    return new web3.value.eth.Contract(VESING_ABI, vestingContractAddress);
  }
  return null;
});

async function fetchVestingData(): Promise<IVesting> {
  if (!vestingContract.value) {
    throw new Error('Web3 is not initialized');
  }

  const addressValue = address.value;

  const [vestingSchedule, claimableAmount]: [VestingScheduleDto, string] =
    await Promise.all([
      vestingContract.value.methods
        .getVestingSchedule(addressValue)
        .call({ from: addressValue }),
      vestingContract.value.methods
        .getReleasableAmount(addressValue)
        .call({ from: addressValue }),
    ]);

  return {
    totalVest: new TokenAmount(vestingSchedule.amountTotal, bluesDecimals),
    alreadyClaimed: new TokenAmount(
      vestingSchedule.amountReleased,
      bluesDecimals,
    ),
    claimableAmount: new TokenAmount(claimableAmount, bluesDecimals),
    startDate: unixTimeToDate(vestingSchedule.start),
    monthsPeriod: secondsToMonths(vestingSchedule.duration),
    totalInPool: new TokenAmount(
      toWei(TOTAL_BLUES_AMOUNT, bluesDecimals),
      bluesDecimals,
    ),
  };
}

const vestingState = ref<IVesting | null>(null);

const vestingStateIsReady = computed(() => !!vestingState.value);

watch([address, isNetworkError], async ([address, isNetworkError]) => {
  vestingState.value = null;
  if (address && !isNetworkError) {
    vestingState.value = await fetchVestingData();
  }
});

const isNotListed = computed(() => {
  if (!vestingState.value) {
    return null;
  }
  return vestingState.value.totalVest.weiAmount.eq(0);
});

function useFormattedVestingField(
  field: 'totalVest' | 'alreadyClaimed' | 'claimableAmount' | 'totalInPool',
): ComputedRef<string> {
  return computed(() => {
    if (!vestingStateIsReady.value) {
      return '';
    }
    return format(vestingState.value![field].relativeAmount as BigNumber);
  });
}

export function useVesting() {
  async function claim(
    onTransactionHash?: (hash: string) => void,
  ): Promise<TransactionReceipt> {
    if (!vestingContract.value) {
      throw new Error('Web3 is not initialized');
    }

    const addressValue = address.value;

    // test call before execution
    await vestingContract.value.methods
      .release(addressValue)
      .call({ from: addressValue });

    return new Promise((resolve, reject) => {
      vestingContract
        .value!.methods.release(addressValue)
        .send({ from: addressValue })
        .on('transactionHash', onTransactionHash)
        .on('receipt', async (receipt: TransactionReceipt) => {
          await updateBalance();
          resolve(receipt);
        })
        .on('error', (err: unknown) => {
          console.error(`Method execution error. ${err}`);
          reject(err);
        });
    });
  }

  async function updateVesting() {
    if (!vestingContract.value || isNetworkError.value) {
      return;
    }

    vestingState.value = await fetchVestingData();
  }

  return {
    updateVesting,
    claim,
    isNotListed,
    isReceivedAll: computed(() => {
      if (!vestingState.value) {
        return null;
      }
      return vestingState.value.totalVest.weiAmount.eq(
        vestingState.value.alreadyClaimed.weiAmount,
      );
    }),
    vestingStateIsReady,
    vestingInfo: computed(() => vestingState.value),
    totalVestFormatted: useFormattedVestingField('totalVest'),
    alreadyClaimedFormatted: useFormattedVestingField('alreadyClaimed'),
    claimableAmountFormatted: useFormattedVestingField('claimableAmount'),
    startDate: computed(() => vestingState.value?.startDate),
    startDateFormatted: computed(() =>
      vestingState.value?.startDate
        ? formatDate(vestingState.value.startDate as Date)
        : '',
    ),
    monthsPeriod: computed(() => vestingState.value?.monthsPeriod),
    totalInPoolFormatted: useFormattedVestingField('totalInPool'),
  };
}
