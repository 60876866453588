<template>
  <div class="flex flex-column align-items-center py-s10">
    <div class="text-center h2">
      You have received all your BLUES tokens according to the vesting scheme.
    </div>
    <div class="mt-s4">Thank you for your participation!</div>
  </div>
</template>

<script setup></script>

<style scoped lang="scss"></style>
