<template>
  <div
    class="flex flex-column content-error-page align-items-center py-s6 md:py-0"
    :style="isMobile ? 'width: 230px' : 'width: 400px'"
  >
    <Icon
      name="dangerOutline"
      :size="isMobile ? '100' : '160'"
      class="align-self-center"
    />
    <div class="my-s6 text-center" :class="isMobile ? 'h3' : 'h2'">
      Please select the Milkomeda Cardano C1 network in your wallet
    </div>
    <div class="text-muted text-center">
      This application doesn’t support the network selected in your wallet
    </div>
    <Button class="button-primary mt-s5" @click="onSwitchNetwork">
      Switch network
    </Button>
  </div>
</template>

<script setup>
import Icon from '@/components/Icon';
import { useLayout } from '@/composables/core/useLayout';
import { useWallet } from '@/composables/core/useWallet';
import { switchNetwork } from '@/helpers/blockchain-utils';

const { isMobile } = useLayout();
const { getFirstSupportedNetwork } = useWallet();

const onSwitchNetwork = () => {
  switchNetwork(getFirstSupportedNetwork());
};
</script>

<style scoped></style>
